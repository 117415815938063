<template>
    <div class="business-container">
        <div class="chart" id="chart_right1"></div>
    </div>
</template>

<script>
import api from "@/api"

export default {
    name: 'exam-finish-info',
    data() {
        return {}
    },
    mounted() {
        this.getEchartRight1();
    },
    methods: {
        async  getEchartRight1() {
            let myChart = echarts.init(document.getElementById('chart_right1'));
            let charts = {
                x1Data: [2145,1500,2500,1500,0,0,0,2350,1569,201,150],
                x2Data: [2500,2500,6500,3540,120,0,2350,1569,201,200],
                yData: ['中央形考','笔试','机考','易考通','大作业','分校形考','论文','学位英语','学分替代','待定','手机考试','口试']
            }
            let x1Value = [];
            let x2Value = [];
            let yValue = charts.yData;
            let res = await api.bigdata.getExamFinishInfo({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                x1Value = [res.data.zyxkArrangeNum,res.data.bsArrangeNum,res.data.jkArrangeNum,res.data.yktArrangeNum,
                    res.data.dzyArrangeNum,res.data.fxxkArrangeNum,res.data.lwArrangeNum,res.data.xwyyArrangeNum,
                    res.data.xftdArrangeNum,res.data.ddArrangeNum,res.data.sjksArrangeNum,res.data.ksArrangeNum];
                x2Value = [res.data.zyxkJoinNum,res.data.bsJoinNum,res.data.jkJoinNum,res.data.yktJoinNum,
                    res.data.dzyJoinNum,res.data.fxxkJoinNum,res.data.lwJoinNum,res.data.xwyyJoinNum,
                    res.data.xftdJoinNum,res.data.ddJoinNum,res.data.sjksJoinNum,res.data.ksJoinNum];
            }
            let color = ['#249bff','#24ffee'];


            // 指定配置和数据
            let option = {
                color: color,
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    x: 'right',
                    y: 'top',
                    textStyle: {color: '#fff'},
                    data: ['教师排考', '学生已参加']
                },
                grid: {
                    borderWidth: 0,
                    top: '5%',
                    left: '2%',
                    right: '2%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'value',
                    name: '教师排考',
                    axisTick: { // 坐标轴 刻度
                        show: true
                    },
                    axisLine: { // 坐标轴 轴线
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: { // 坐标轴标签
                        show: true, // 是否显示
                        interval:0,//代表显示所有x轴标签显示
                        inside: false,  // 是否朝内
                        textStyle: { // 样式
                            color: '#b3ccf8',
                            fontSize: 13
                        },
                    }
                }],
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisTick: {
                        show: true
                    },
                    axisLine: {
                        show: true
                    },
                    axisLabel: {
                        show: true,
                        inside: false,
                        textStyle: {
                            color: '#b3ccf8',
                            fontSize: 13
                        },
                    },
                    data: yValue
                }],
                series: [{
                    name: '教师排考',
                    type: 'bar',
                    zlevel: 2,
                    barWidth: '10px',
                    data: x1Value,
                    animationDuration: 1500,
                    label: {
                        normal: {
                            color: '#b3ccf8',
                            show: false,
                            position: [0, '-15px'],
                            textStyle: {
                                fontSize: 13
                            },
                            formatter: (a, b) => {
                                return a.name;
                            }
                        }
                    }
                },{
                    name: '学生已参加',
                    type: 'bar',
                    zlevel: 2,
                    barWidth: '10px',
                    data: x2Value,
                    animationDuration: 1500,
                    label: {
                        normal: {
                            color: '#b3ccf8',
                            show: false,
                            position: [0, '-15px'],
                            textStyle: {
                                fontSize: 13
                            },
                            formatter: (a, b) => {
                                return a.name;
                            }
                        }
                    }
                }]
            };

            // 把配置给实例对象
            myChart.setOption(option, true);
            window.addEventListener('resize', () => {
                myChart.resize();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .business-container {
        .chart {
            height: 10rem;
        }
    }
</style>