<template>
    <div class="brand-container">
        <div class="wrap">
            <header>
                <div class="weather" />
                <h2>考试监测</h2>
                <div class="showTime" />
            </header>

            <section class="mainbox">
                <div class="item left">
                    <div class="panel" style="height: 100%">
                        <h2>本学期考试完成情况</h2>
                        <exam-finish-info />
                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item center">
                    <div class="resume">
                        <div class="resume-hd">
                            <ul>
                                <li>
                                    <countTo :startVal='startVal' :endVal='todayJoinNum' :duration='6000' separator=""></countTo>
                                </li>
                                <li>
                                    <countTo :startVal='startVal' :endVal='examSubjectNum' :duration='6000' separator=""></countTo>
                                </li>
                            </ul>
                        </div>
                        <div class="resume-bd">
                            <ul>
                                <li>今日参考学生数</li>
                                <li>本学期开考科目数</li>
                            </ul>
                        </div>
                    </div>

                    <div class="panel-live">
                        <div class="resume" style="width: 7rem;margin-left: -1rem;margin-top: 0.5rem">
                            <div class="resume-hd">
                                <h2>学生考点选择情况</h2>
                                <exam-centre-info />
                            </div>
                        </div>

                        <div class="resume" style="width: 7rem;margin-left: -1rem;margin-top: 0.5rem">
                            <div class="resume-hd">
                                <h2>学生报考确认情况</h2>
                                <candidate-info />
                            </div>
                        </div>

                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item right">
                    <div class="panel">
                        <div class="resume">
                            <div class="resume-hd">
                                <h2>本学期考试时间安排</h2>
                                <h2 style="font-size: 0.35rem;color: #ffeb7b;">{{examStartDate}}~{{examEndDate}}</h2>
                            </div>
                        </div>

                        <div class="resume">
                            <div class="resume-hd">
                                <h2>国开网考试时间安排</h2>
                                <h2 style="font-size: 0.35rem;color: #ffeb7b;">{{wkStartDate}}~{{wkEndDate}}</h2>
                            </div>
                        </div>

                        <div class="resume">
                            <span style="float:left;margin-top: 0.4rem;margin-left: 0.35rem">
                                <h2 style="font-size: 0.35rem;color: #ffeb7b;">{{bsStartDate}}~{{bsEndDate}}</h2>
                                <h2>笔试时间</h2>
                            </span>
                            <span style="float:left;margin-top: 0.4rem;margin-left:1.35rem;">
                                <h2 style="font-size: 0.35rem;color: #ffeb7b;">{{yktStartDate}}~{{yktEndDate}}</h2>
                                <h2>易考通时间</h2>
                            </span>
                        </div>

                        <div class="panel-footer"></div>
                    </div>

                    <div class="panel-dynamic">
                        <h2>实时动态</h2>
                        <time-dynamic />
                        <div class="panel-footer"></div>
                    </div>
                </div>
            </section>

        </div>

    </div>
</template>

<script>
import '@/assets/js/flexible'
import countTo from 'vue-count-to'
import ExamFinishInfo from "./c/ExamFinishInfo.vue";
import TimeDynamic from "./c/TimeDynamic.vue";
import ExamCentreInfo from "./c/ExamCentreInfo.vue";
import CandidateInfo from "./c/CandidateInfo.vue";
import api from "@/api"

export default {
    components: {
        CandidateInfo,
        ExamCentreInfo,
        TimeDynamic,
        ExamFinishInfo,
        countTo},
    name: 'exam-monitor',
    data() {
        return {
            startVal: 0,
            todayJoinNum: 0,
            examSubjectNum: 0,
            examStartDate: '',
            examEndDate: '',
            wkStartDate: '',
            wkEndDate: '',
            bsStartDate: '',
            bsEndDate: '',
            yktStartDate: '',
            yktEndDate: '',
        }
    },
    created() {
        this.initOrganizationByDomain();
    },
    mounted() {
        this.getExamCount();
    },
    methods: {
        async initOrganizationByDomain() {
            const organizationId = localStorage.getItem('organizationId')
            if (!organizationId) {
                const domain =  window.location.origin || 100000
                let res = await api.protal.getOrganizationByDomain({ domain })
                if (res.code === 200 && res.data) {
                    localStorage.setItem('organizationId', res.data.organizationId)
                    location.reload();
                }
            }
        },
        async getExamCount() {
            let res = await api.bigdata.getExamCount({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                this.todayJoinNum = res.data.todayJoinNum;
                this.examSubjectNum = res.data.examSubjectNum;
                let moment = require("moment");
                this.examStartDate = moment(res.data.examStartDate).format('YYYY.MM.DD')
                this.examEndDate = moment(res.data.examEndDate).format('YYYY.MM.DD')
                this.wkStartDate = moment(res.data.wkStartDate).format('YYYY.MM.DD')
                this.wkEndDate = moment(res.data.wkEndDate).format('YYYY.MM.DD')
                this.bsStartDate = moment(res.data.bsStartDate).format('MM.DD')
                this.bsEndDate = moment(res.data.bsEndDate).format('MM.DD')
                this.yktStartDate = moment(res.data.yktStartDate).format('MM.DD')
                this.yktEndDate = moment(res.data.yktEndDate).format('MM.DD')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/css/bigdata-screen/today-dynamic.scss";
</style>