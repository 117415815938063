<template>
    <div class="wrap-container sn-container">
        <div class="sn-content">
            <div class="sn-title"></div>
            <div class="sn-body">
                <div class="wrap-container">
                    <div class="table">
                        <table border="0" cellpadding="0" cellspacing="0" class="table-header">
                            <tbody>
                            </tbody>
                        </table>
                    <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="optionSetting">
                        <table border="0" cellpadding="0" cellspacing="0" class="item">
                            <tbody>
                            <tr v-for="(item, index) in listData" :key="index">
                                <td width="100%" class="title">
                                    <p style="text-align: left; font-size: 16px">
                                        {{ item.operTime | setOperTimeTime  }}<span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
                                        {{ item.operUserName }}<span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
                                        {{ item.operType.desc }}<span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
                                        {{ item.beCorrectUserName == null ? item.beCorrectUserName : item.beCorrectUserName + '的'}}<span v-if="item.beCorrectUserName" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
                                        {{ item.title | setTitle }}<span v-if="item.title" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
                                        {{ item.dynamicType.desc }}
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </vue-seamless-scroll>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import api from '@/api'

export default {
    name: "time-dynamic",
    components: {
        vueSeamlessScroll
    },
    data() {
        return {
            listData: []
        }
    },
    filters: {
        setOperTimeTime(val) {
            let moment = require("moment");
            const daterc = moment(val).format('HH:mm')
            return daterc;
        },
        setTitle(val) {
            if (val && val.length > 12) {
                return val.substr(0,12) + "..."
            }
            return val;
        }
    },
    computed: {
        optionSetting () {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                // autoPlay: false,
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
        this.getTimeDynamicList()
    },
    methods: {
        async getTimeDynamicList() {
            let res = await api.bigdata.getExamTimeDynamicList({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                this.listData = res.data;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .sn-container {
        left: 1370px;
        top: 110px;
        %table-style {
            width: 100%;
            height: 35px;
            table-layout: fixed;
            tr {
                td {
                    padding: 10px 5px;
                    text-align: center;
                    background-color: transparent;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #E2E5FF;
                    font-size: 14px;
                }
            }
        }
        .table {
            .table-header {
                @extend %table-style;
            }
            .seamless-warp {
                height: 450px;
                overflow: hidden;
                visibility: visible;
                .colorRed {
                    color: #FF4669;
                }
                .colorOrange {
                    color: #FFC956;
                }
                .item {
                    height: auto;
                    @extend %table-style;
                    tr {
                        td {
                            &.title {
                                text-overflow: ellipsis;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
</style>