<template>
    <div class="distribution-container">
        <div class="chart" id="chart_center2"></div>
    </div>
</template>
<script>
    import api from '@/api'
    export default {
        name: "candidate-info",
        data() {
            return {}
        },
        mounted() {
            this.getEchartCenter2();
        },
        methods: {
            async getEchartCenter2() {
                let myChart = echarts.init(document.getElementById('chart_center2'));
                let data = [];
                let res = await api.bigdata.getConfirmInfo({organizationId: localStorage.getItem('organizationId')})
                if (res.code === 200) {
                    data = [{
                        value: res.data.confirmNum,
                        name: '已确认'
                    },{
                        value: res.data.unConfirmNum,
                        name: '未确认'
                    }]
                }
                let option = {
                    color: ['#EAEA26', '#906BF9'],
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {c} ({d}%)'
                    },
                    series: [{
                        name: '环形饼图',
                        type: 'pie', //饼图、环形图的type一样
                        radius: ['35%', '65%'], //环形图的半径，数组的第一项是内半径，第二项是外半径
                        //roseType: 'area', //是否显示成南丁格尔图
                        label: {
                            normal: {
                                show: true,
                                formatter: '{b}',
                                textStyle: {
                                    fontSize: 14,
                                },
                                position: 'outside',
                            },
                            emphasis: {
                                show: false
                            }
                        },
                        labelLine: {
                            normal: {
                                show: true,
                                length: 15,
                                length2: 50,
                                lineStyle: {
                                    type: 'dotted'
                                }
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        data: data
                    }]
                }
                myChart.setOption(option, true);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .distribution-container {
        .chart {
            height: 3rem;
        }
    }
</style>